<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import SettingsMixin from "@/modules/settings/mixins/SettingsMixin";
import FormSettings from "@/modules/settings/components/Form.vue";
import { Settings } from "@planetadeleste/vue-mc-gw";

@Component({
  components: { FormSettings },
})
export default class SettingsForm extends Mixins(SettingsMixin) {
  onMounted() {
    this.obItem = new Settings();
    this.$emit("input", this.obItem);

    this.index();
  }

  onRegisterEvents() {
    this.addEvent(`${this.sModelName}.after.save`, this.index);
  }

  async index() {
    await this.obItem.fetch();
  }
}
</script>

<template>
  <form-settings v-model="obItem" />
</template>
