<template>
  <v-btn :loading="loading" color="primary" @click="onCleanCache">
    <icon-trash class="mr-2" />
    {{ $t("clean.cache") }}
  </v-btn>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import useUtils from "@/services/Utils";
import IconTrash from "@/components/icons/IconTrash.vue";

@Component({
  components: { IconTrash },
})
export default class BtnCleanCache extends Vue {
  loading = false;

  async onCleanCache() {
    this.loading = true;
    await useUtils.cleanApiCache();
    this.loading = false;
  }
}
</script>
