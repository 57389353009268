<template>
  <v-form class="fill-height" @submit.prevent v-if="obModel.attributes">
    <gw-form-observer @save="save" @cancel="cancel" :loading="isLoading">
      <settings v-model="obModel" />

      <template #bottom-actions>
        <btn-clean-cache />
      </template>
    </gw-form-observer>
  </v-form>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import SettingsMixin from "@/modules/settings/mixins/SettingsMixin";
import Settings from "@/modules/settings/components/Settings.vue";
import BtnCleanCache from "@/modules/settings/components/BtnCleanCache.vue";

@Component({
  components: {
    BtnCleanCache,
    Settings,
  },
})
export default class SettingsesForm extends Mixins(SettingsMixin) {}
</script>
