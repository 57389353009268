<template>
  <module-container>
    <settings-form />
  </module-container>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";
import SettingsForm from "@/modules/settings/views/Form.vue";

@Component({
  components: { SettingsForm },
})
export default class SettingsIndex extends Mixins(PaginateProviderMixin) {}
</script>
